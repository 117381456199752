<template>
  <div class = "footer_container" >
    <div>
   Copyright ©2021  yingweitech.cn 版权所有 <a href="https://beian.miit.gov.cn/" target="_blank">备案：闽ICP备2022007129号-1</a>
    </div>
       
    
    
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      footerHeight: '',
      curWidth: '',
      logo_url: require('../../assets/images/logo.png'),
      contact_way: [ 
        {
          name: '媒体问询',
          email: 'aerowangue@126.com'
        },
        {
          name: '招聘相关',
          email: 'aerowangue@126.com'
        },
        {
          name: '商务合作',
          email: 'aerowangue@126.com'
        },
        {
          name: '广告相关',
          email: 'aerowangue@126.com'
        }
      ],
     
    }
  },
  methods: {
    // setFooterHeight () {
    //   this.curWidth = window.innerWidth
    //   if (this.curWidth <= 991) {
    //     this.footerHeight = 570 + 'px'
    //   } else {
    //     this.footerHeight = 280 + 'px'
    //   }
    // }
  },
  created () {
    // 页面创建时执行一次getHeight进行赋值，顺道绑定resize事件
    // window.addEventListener('resize', this.setFooterHeight)
    // this.setFooterHeight()
  }
}
</script>

<style lang = "scss" scoped>
*{
  margin: 0;
  padding: 0;
}
h2{
  font-size: 100%;
  font-weight: 400;
  font-variant: normal;
}
.footer_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #1f2329;
  color:#fff;
  padding: 20px;
  position:absolute;
  bottom: 0px;
}

.footer_content {
  max-width: 1440px;
  color: #fff;
  margin: 0 auto;
  padding: 69px 0 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  h2 {
    font-size: 14px;
    line-height: 22px;
    margin: 4px 0;
    color: #7b7e81;
  }

  .footer-content-column {
    flex: 1 1;
    color: #d2d3d4;
    position: relative;
    text-align: center;

    h2 {
      margin-bottom: 18px;
      font-size: 16px;
      font-weight: bold;
    }

    .about_list, .contact_list {
      text-align: center;
      font-size: 14px;
      line-height: 22px;

      li {
        margin-bottom: 15px;
      }

      a {
        color: #d2d3d4;
      }
    }
  }

  .about_us, .contact_us {
    max-width: 202px;
  }

  .focus_us {
    text-align: left;
    padding-left: 73px;

    .media {
      margin-top: 18px;
      display: flex;
    }

    span {
      margin-right: 18px;
      flex: 0 0 auto;
    }

    img {
      width: 32px;
    }

    .weixin img {
      width: 38px;
      position: relative;
      top: -2px;
    }

    h2 {
      text-align: left;
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 4px;
    }
  }

  .footer_logo {
    margin-left: 96px;
    height: 55px;
    text-align: left;
    max-width: 382px;
    min-width: 350px;

    h2 {
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 4px;
    }

    .logo-container {
      display: flex;
      margin-bottom: 9px;
      img {
        height: 50px;
      }
      .logo-text{
        margin-left: 15px;
        position: relative;
        bottom: -6px;
      }
      .text1{
        font-size: 21px;
        font-weight: bold;
        margin: 0;
      }
      .text2{
        font-size: 12px;
        margin: 0;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .footer_content {
    flex-direction: column;

    .footer-content-column {
      text-align: center;
      margin: 0 auto 0;

      h2 {
        text-align: center;
      }
      padding-left: 0;
    }
    .focus_us div:last-of-type > span{
      margin-right: 0;
    }
    .media{
      justify-content: center;
    }
    .logo-container{
      justify-content: center;
      text-align: left;
    }
  }
}

.qr_popover {
  width: 100%;
  text-align: center;

  img {
    width: 130px;
    height: 130px;
    margin-bottom: 10px;
  }
}
</style>
