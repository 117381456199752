<template>
  <div class="login" :style= login>
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      label-position="left"
      label-width="0px"
      class="login-form"
    >
      <h3 class="title">{{ title }}</h3>
      <el-form-item prop="username">
        <el-input
          v-model="loginForm.username"
          type="text"
          auto-complete="off"
          placeholder="账号"
        >
          <svg-icon
            slot="prefix"
            icon-class="user"
            class="el-input__icon input-icon"
          />
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          v-model="loginForm.password"
          type="password"
          auto-complete="off"
          placeholder="密码"
          @keyup.enter.native="handleLogin"
        >
          <svg-icon
            slot="prefix"
            icon-class="password"
            class="el-input__icon input-icon"
          />
        </el-input>
      </el-form-item>
      <el-form-item prop="code">
        <el-input
          v-model="loginForm.code"
          auto-complete="off"
          placeholder="验证码"
          style="width: 63%"
          @keyup.enter.native="handleLogin"
        >
          <svg-icon
            slot="prefix"
            icon-class="validCode"
            class="el-input__icon input-icon"
          />
        </el-input>
        <div class="login-code">
          <img ref="verifyCode" :src="codeUrl" @click="getCode" />
        </div>
      </el-form-item>
      <el-form-item style="width: 100%">
        <el-button
          :loading="loading"
          size="medium"
          type="primary"
          style="width: 100%"
          @click.native.prevent="handleLogin"
        >
          <span v-if="!loading">登 录</span>
          <span v-else>登 录 中...</span>
        </el-button>
      </el-form-item>
    </el-form>
     <AwFooter></AwFooter>
  </div>
</template>

<script>
import { getVerifyCode } from "@/api/login";
import defaultSettings from "@/settings";
import AwFooter from '../components/public/Footer'
export default {
  name: "login",
  components: {
    
    AwFooter,
    
  },
  data() {
    return {
      login: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        backgroundColor: "#2d3a4b",
        backgroundSize: "cover",

        backgroundImage: "url(" + require("../assets/images/com.jpg") + ")",
        backgroundRepeat: "no-repeat",
       // backgroundSize: "25px auto",
      },

      title: defaultSettings.title,
      codeUrl: "",
      loginForm: {
        username: "",
        password: "",
        code: "",
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", message: "用户名不能为空" },
        ],
        password: [
          { required: true, trigger: "blur", message: "密码不能为空" },
        ],
        code: [
          { required: true, trigger: "change", message: "验证码不能为空" },
        ],
        uuid: "",
      },
      loading: false,
    };
  },
  created() {
    this.getCode();
  },
  methods: {
    getCode() {
      getVerifyCode().then((res) => {
        // console.log(res,'----------------------------');
        this.codeUrl = res.data.captchaCode;
        this.loginForm.uuid = res.data.captchaGUID;
      });
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        const user = {
          username: this.loginForm.username,
          password: this.loginForm.password,
          code: this.loginForm.code,
          uuid: this.loginForm.uuid,
        };
        if (valid) {
          this.loading = true;
          this.$store
            .dispatch("Login", user)
            .then((response) => {
              this.loading = false;
              if (response.statusCode === 200) {
                this.$router.push({ path: "/dashboard" });
              } else {
                this.getCode();
              }
            })
            .catch(() => {
              this.loading = false;
              this.getCode();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss">
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  background-color: #2d3a4b;
  background-size: cover;
  background: url("/asset/images/background.jpg");
}
.title {
  margin: 0 auto 30px auto;
  text-align: center;
  color: #707070;
}

.login-form {
  border-radius: 6px;
  background: #ffffff;
  width: 385px;
  padding: 25px 25px 5px 25px;
  .el-input {
    height: 38px;
    input {
      height: 38px;
    }
  }
  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
}
.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}
.login-code {
  width: 33%;
  display: inline-block;
  height: 38px;
}

.login-code img {
  cursor: pointer;
  vertical-align: middle;
}
</style>
